<template>
  <div class="video_course_box">
    <div class="content">
      <div class="options_box" v-if="isShowOptions">
        <div class="options_list" v-for="(item,i) in VideoOptionsList" :key="i">
          <p class="type_title">{{item.first_category}}:</p>
          <ul>
            <li @click="onOptionBtn(option)" :class="{'is_option': optionId ===option.id }"
                v-for="option in item.subList" :key="option.id">
              {{option.secondCategory === '耳鼻咽喉头颈外科'? '耳鼻喉科' : option.secondCategory}}</li>
          </ul>
        </div>
      </div>

      <div class="video_box">
        <ul class="video_list">
          <li @click="goVideoDetailsPage(video)" v-for="video in videoList" :key="video.id">
            <img v-if="video.isVip === 1" class="vipicon"
                 src="@/assets/img/infoCollege/video_vip_icon.png" alt="icon">
            <div class="img_box">
              <img class="videoPic" :src="video.videoPic" alt="视频封面">
            </div>
            <p>{{video.title}}</p>
            <i class="video_time">{{video.videoDuration}}分钟</i>
          </li>
        </ul>
        <div class="btn_box loading">
          <span v-if="isShow" @click="onGetMore">加载更多</span>
          <span v-if="!isOccupation && !isShow" style="color:#9195a3;">已经到底啦~</span>
        </div>
        <p v-if="isOccupation" class="occupation my_card">暂无视频</p>
      </div>
    </div>
    <!-- 非会员弹窗 v-show="noVip"-->
    <el-dialog :visible.sync="noVip" width="30%" :show-close="false">
      <div class="no-vip">
        <i @click="handleClose" class="el-icon-circle-close"></i>
        <img class="title-img" src="@/assets/img/pdf/vip.png">
        <div>下载APP开通会员，享受专属特权</div>
        <img class="ma" src="@/assets/img/index/dowAPP.png">
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getVideoOptionsList, getVideoList, searchCollege } from '@/api/infoCollege.js'
import { mapGetters } from 'vuex'
export default {
  props: {
    isShowOptions: {
      type: Boolean,
      default: true
    },
    keyword: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      VideoOptionsList: [], // 选项列表
      optionId: '', // 选各种类型
      videoType: '', // 视频分类
      videoList: [], // 视频列表
      pageObj: {
        pageNum: 1,
        pageSize: 15
      },
      isShow: true, // 加载更多状态位
      isOccupation: false, // 缺醒状态位
      noVip: false // 会员权益提示框状态位
    }
  },
  methods: {
    // 关闭会员权益提醒
    handleClose () {
      this.noVip = false
    },
    // 跳转到视频详情页
    goVideoDetailsPage (video) {
      if (!this.userInfo.userId) {
        return this.$login()
      }
      if (video.isVip === 1 && !this.userInfo.isHy) {
        this.noVip = true
        return
      }
      this.$myCount({
        name: 'PC-视频课程观看',
        itemId: video.id
      })
      sessionStorage.setItem('videoObj', JSON.stringify({
        videoId: video.id,
        videoType: this.videoType,
        optionId: this.optionId,
        modularType: 'video'
      }))
      this.$router.push('/videoDetails')
    },
    // 加载更多
    onGetMore () {
      this.pageObj.pageNum++
      this.getVideoList()
    },
    // 获取视频列表
    async getVideoList () {
      try {
        let res = {}
        if (this.isShowOptions) {
          // 普通数据
          res = await getVideoList({
            ...this.pageObj,
            categoryId: this.optionId
          })
        } else {
          // 搜索数据
          res = await searchCollege({
            ...this.pageObj,
            keyword: this.keyword,
            category: 1
          })
        }
        this.isShow = res.data.length >= this.pageObj.pageSize
        this.videoList = [...this.videoList, ...res.data]
        if (this.videoList.length === 0) {
          this.isOccupation = true
        } else {
          this.isOccupation = false
        }
      } catch (error) {
        this.$message.error('获取视频列表失败')
      }
    },
    onOptionBtn (option) {
      this.optionId = option.id
      this.videoType = option.secondCategory
      this.videoList = []
      this.pageObj.pageNum = 1
      // document.documentElement.scrollTop = 162
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.getVideoList()
    },
    // 获取学科列表
    async getOptionsList () {
      try {
        const res = await getVideoOptionsList({ type: 2 })
        this.VideoOptionsList = res.data
        this.onOptionBtn(this.VideoOptionsList[0].subList[0])
      } catch (error) {
        this.$message.error('获取分类列表失败')
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {
    isShowOptions () {
      this.pageObj.pageNum = 1
      this.videoList = []
      this.getVideoList()
    }
  },
  created () {
    this.getOptionsList()
  }
}
</script>
<style lang="less" scoped>
.video_course_box {
  width: 100%;
  min-height: calc(100% - 63px);
}
.content {
  width: 100%;
  min-height: 500px;
  .options_box {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 2px;
    .options_list {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    .type_title {
      width: 70px;
      // height: 18px;
      flex-shrink: 0;
      font-weight: 600;
      color: #4b639f;
      font-size: 14px;
      text-align: justify;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      li {
        height: 30px;
        padding: 0 10px;
        line-height: 30px;
        text-align: center;
        color: #4d4d4d;
        border-radius: 2px;
        cursor: pointer;
        margin-right: 5px;
        &:hover {
          background-color: #e8ecf6;
          color: #4b639f;
        }
      }
      .is_option {
        background-color: #e8ecf6;
        color: #4b639f;
      }
    }
  }
  .video_box {
    width: 100%;
    padding: 20px 0;
    .video_list {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      li {
        position: relative;
        width: 18.63%;
        height: 250px;
        padding: 20px;
        background-color: #fff;
        margin: 0 20px 20px 0;
        border-radius: 5px;
        cursor: pointer;
        top: 0;
        transition: top 0.3s;
        &:nth-child(5n + 0) {
          margin-right: 0;
        }
        &:hover {
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          top: -5px;
          .videoPic {
            transform: scale(1.15);
          }
        }
        .vipicon {
          position: absolute;
          left: 20px;
          bottom: 5px;
          z-index: 90;
          height: 14px;
          border-radius: 0 2px 0 0;
        }
        .videoPic {
          width: 100%;
          transform: scale(1.01);
          transition: transform 0.4s;
        }
        .img_box {
          position: relative;
          width: 100%;
          overflow: hidden;
        }
        p {
          margin-top: 10px;
          line-height: 20px;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .video_time {
          position: absolute;
          bottom: 8px;
          right: 10px;
          color: #9195a3;
          font-size: 12px;
        }
      }
    }
    .btn_box {
      // height: 30px;
      font-size: 14px;
      text-align: center;
      span {
        line-height: 30px;
        cursor: pointer;
        color: #4b639f;
        &:hover {
          font-weight: 700;
        }
      }
    }
    .occupation {
      text-align: center;
      font-size: 16px;
      line-height: 100px;
    }
  }
}
// 不是会员提醒弹框
/deep/.el-dialog {
  background-color: transparent;
  box-shadow: 0 0 0 0 transparent;
  .el-dialog__body {
    padding: 0;
  }
}
.no-vip {
  margin: auto;
  width: 380px;
  height: 375px;
  overflow: hidden;
  border-radius: 15px;
  .title-img {
    width: 100%;
  }
  div {
    width: 100%;
    height: 100%;
    padding: 25px 0 0;
    line-height: 40px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    background-color: #fff;
  }
  i {
    position: absolute;
    right: 45px;
    top: 111px;
    font-size: 28px;
    color: #666666;
  }
  .ma {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 22px;
  }
}
</style>
