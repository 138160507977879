import request from '../utils/request.js'
// 学院模块接口

// 获取视频课程分类
export function getVideoOptionsList (data) {
  return request({
    url: '/college/video/getAllCategory2',
    method: 'POST',
    data
  })
}

// 获取视频列表
export function getVideoList (data) {
  return request({
    url: '/college/video/getVideoByCategoryId2',
    method: 'POST',
    data
  })
}

// 获取视频详情
export function getVideoDetails (data) {
  return request({
    url: '/college/video/getById',
    method: 'POST',
    data
  })
}

// 获取精选资料分类
export function getDocumentAllCategory (data) {
  return request({
    url: '/college/document/getAllCategory',
    method: 'POST',
    data
  })
}

// 获取资料列表
export function getDocumentList (data) {
  return request({
    url: '/college/document/getByClassify',
    method: 'POST',
    data
  })
}

// 获取资料详情
export function getDocumentDetails (data) {
  return request({
    url: '/college/document/getById',
    method: 'POST',
    data
  })
}

// 获取会议回放列表
export function getPlaybackList (data) {
  return request({
    url: '/college/meeting/getPlaybackList',
    method: 'POST',
    data
  })
}

// 获取会议回放详情
export function getPlaybackDetails (data) {
  return request({
    url: '/college/meeting/getPlaybackById',
    method: 'POST',
    data
  })
}

// 学院搜索
export function searchCollege (data) {
  return request({
    url: 'college/searchCourse',
    method: 'POST',
    data
  })
}
