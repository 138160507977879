<template>
  <div class="selected_data_course_box">
    <div class="content">
      <div class="options_box" v-if="isShowOptions">
        <ul>
          <li @click="onOptionClick(item)" :class="{'is_option': categoryId === item.id}" v-for="item in categoryList" :key="item.id">{{item.name}}</li>
        </ul>
      </div>

      <div class="selected_data_box">
        <ul>
          <li @click="goSelectedDocumentPage(item)" v-for="item in documentList" :key="item.id">
            <div class="img_box">
              <img class="filePic" :src="item.filePic" alt="封面">
            </div>
            <p class="title">{{item.title}}</p>
            <div class="author">{{item.author}}</div>
          </li>
        </ul>
        <div class="btn_box loading">
          <span v-if="isGetMoreBtn" @click="onGetMoreDocument">加载更多</span>
          <span v-if="!isOccupation && !isGetMoreBtn" style="color:#9195a3;">已经到底啦~</span>
        </div>
        <p v-if="isOccupation" class="occupation my_card">暂无数据</p>
      </div>
    </div>

  </div>
</template>
<script>
import { getDocumentAllCategory, getDocumentList, searchCollege } from '@/api/infoCollege.js'
import { mapGetters } from 'vuex'
export default {
  props: {
    isShowOptions: {
      type: Boolean,
      default: true
    },
    keyword: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      categoryList: [],
      categoryId: null,
      categoryName: '',
      documentList: [], // 资料列表
      pageObj: {
        pageNum: 1,
        pageSize: 16
      },
      isGetMoreBtn: true, // 加载更多状态位
      isOccupation: false // 缺醒状态位
    }
  },
  methods: {
    // 跳转详情页
    goSelectedDocumentPage (item) {
      if (!this.userId) {
        return this.$notify({
          title: '提示信息',
          message: '请您先登录再进行查看',
          offset: 100,
          type: 'warning'
        })
      }
      sessionStorage.setItem('documentParameter', JSON.stringify({
        categoryId: this.categoryId,
        categoryName: this.categoryName,
        documentId: item.id
      }))
      this.$router.push('/documentDetails')
    },
    // 获取更多
    onGetMoreDocument () {
      this.pageObj.pageNum++
      this.getDocumentList()
    },
    // 获取文献列表
    getDocumentList () {
      this.$nextTick(async () => {
        let res = {}
        if (this.isShowOptions) {
          // 普通
          res = await getDocumentList({ ...this.pageObj, classify: this.categoryId })
        } else {
          // 搜索
          res = await searchCollege({
            ...this.pageObj,
            keyword: this.keyword,
            category: 2
          })
        }
        this.isGetMoreBtn = res.data.length >= this.pageObj.pageSize
        const dataList = res.data
        this.documentList = [...this.documentList, ...dataList]
        if (this.documentList.length === 0) {
          this.isOccupation = true
        } else {
          this.isOccupation = false
        }
      })
    },
    // 分类选中
    onOptionClick (item) {
      this.categoryId = item.id
      this.categoryName = item.name
      this.pageObj.pageNum = 1
      this.documentList = []
      this.getDocumentList()
    },
    // 回去分类
    async getDocumentAllCategory () {
      const res = await getDocumentAllCategory()
      this.categoryList = res.data
      this.onOptionClick(res.data[0])
    }
  },
  computed: {
    ...mapGetters(['userId'])
  },
  watch: {
    isShowOptions () {
      this.pageObj.pageNum = 1
      this.documentList = []
      this.getDocumentList()
    }
  },
  created () {
    this.getDocumentAllCategory()
  }
}
</script>
<style lang="less" scoped>
.selected_data_course_box {
  width: 100%;
  min-height: calc(100% - 63px);
}
.selected_data_title {
  width: 75px;
  flex-shrink: 0;
  font-weight: 600;
  color: #4b639f;
  font-size: 14px;
}
.content {
  width: 100%;
  min-height: 540px;
  .options_box {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 0 0 2px 2px;
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      li {
        height: 30px;
        padding: 0 10px;
        line-height: 30px;
        text-align: center;
        color: #4d4d4d;
        border-radius: 2px;
        cursor: pointer;
        margin-right: 5px;
        // &:nth-child(odd) {
        //   margin: 0 7% 10px 10%;
        // }
        // &:nth-child(even) {
        //   margin: 0 10% 10px 6%;
        // }
        &:hover {
          background-color: #e8ecf6;
          color: #4b639f;
        }
      }
      .is_option {
        background-color: #e8ecf6;
        color: #4b639f;
      }
    }
  }
  .selected_data_box {
    width: 100%;
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0px;
      li {
        position: relative;
        display: flex;
        width: 23.38%;
        height: 120px;
        background-color: #fff;
        border-radius: 2px;
        margin: 0 25px 20px 0;
        padding: 20px;
        cursor: pointer;
        top: 0;
        transition: top 0.3s;
        &:hover {
          top: -5px;
          box-shadow: 4px 4px 10px rgba(204, 204, 204, 0.5),
            -2px -3px 7px #ffffff;
          transition: all 0.2s;
          .filePic {
            transform: scale(1.15);
          }
        }
        &:nth-child(4n + 0) {
          margin-right: 0;
        }
        .filePic {
          width: 100%;
          transform: scale(1.01);
          transition: transform 0.4s;
        }
        .title {
          font-size: 14px;
          line-height: 20px;
          height: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .author {
          position: absolute;
          bottom: 5px;
          right: 10px;
          width: 80%;
          text-align: end;
          font-size: 12px;
          color: #9195a3;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .img_box {
          position: relative;
          width: 106px;
          height: 100%;
          flex-shrink: 0;
          margin-right: 10px;
          overflow: hidden;
        }
      }
    }

    .btn_box {
      // height: 40px;
      width: 100%;
      text-align: center;
      span {
        display: inline-block;
        padding-bottom: 20px;
        font-size: 14px;
        cursor: pointer;
        color: #4b639f;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
  .occupation {
    text-align: center;
    font-size: 16px;
    line-height: 100px;
  }
}
</style>
