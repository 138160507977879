<template>
  <div class="info_college">
    <div class="info_college_content_box">
      <div class="content">
        <div class="top_search_box mb20">
          <div class="open_search_box">
            <span class="my_btn_slide "
                  @click="onOpensearchInput">{{isShowSearch?'关闭搜索': '搜索'}}</span>
          </div>
          <div :class="{'hide_search_input':!isShowSearch}"
               class="search_input display_flex alignItems_center">
            <el-input placeholder="请输入关键词搜索" v-model="keyword" clearable>
            </el-input>
            <span class="search_btn flex_shrink0 fs14 hand" @click="onSearch">搜索</span>
          </div>
        </div>
        <ul class="tabs_box">
          <li @click="onTabChange(tab)" v-for="(tab, i) in tabsList" :key="i"
              :class="{isTab: componentName===tab.componentName}">
            <!-- <i class="iconfont" :class="tab.icon"></i> -->
            <span>{{tab.name}}</span>
          </li>
          <li ref="line" class="line"></li>
        </ul>
        <keep-alive v-if="isDom">
          <component :is="componentName" :isShowOptions="isShowOptions" :keyword="keyword">
          </component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import VideoCourse from './components/vdeoCourse.vue'
import selectedData from './components/selectedData.vue'
import playback from './components/playback.vue'
export default {
  components: {
    VideoCourse,
    selectedData,
    playback
  },
  data () {
    return {
      componentName: 'VideoCourse',
      tabsList: [
        {
          icon: 'icon-shipin',
          name: '视频课程',
          componentName: 'VideoCourse',
          category: 1
        },
        {
          icon: 'icon-text',
          name: '精选资料',
          componentName: 'selectedData',
          category: 2
        },
        {
          icon: 'icon-huiyi',
          name: '会议回放',
          componentName: 'playback',
          category: 4
        }
      ],
      isShowSearch: false, // 搜索状态位
      keyword: '', // 搜索关键词
      isShowOptions: true, // 学科选择
      isDom: true
    }
  },
  methods: {
    // 搜索按钮
    onSearch () {
      this.isShowOptions = false
      this.isDom = false
      this.onTabChange(this.tabsList[0])
      this.$nextTick(() => {
        this.isDom = true
      })
    },
    // 打开搜索框
    onOpensearchInput () {
      this.isShowSearch = !this.isShowSearch
      if (!this.isShowSearch) {
        this.keyword = ''
        this.isShowOptions = true
        this.onTabChange(this.tabsList[0])
      }
    },
    // tab切换事件
    onTabChange (tab) {
      this.componentName = tab.componentName
      const line = this.$refs.line
      if (tab.componentName === 'VideoCourse') {
        line.style.transform = 'translateX(0px)'
      } else if (tab.componentName === 'selectedData') {
        line.style.transform = 'translateX(110px)'
      } else {
        line.style.transform = 'translateX(220px)'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.info_college {
  width: 100%;
  min-height: 100%;
  .info_college_content_box {
    width: 100%;
    background-color: #f5f7fb;
    min-height: calc(100vh - 252px);
    .content {
      padding-top: 10px;
      width: 1200px;
      margin: auto;
    }
  }
  .top_search_box {
    .open_search_box {
      text-align: end;
    }
    .my_btn_slide {
      width: 60px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #4b639f;
      border: 1px solid #4b639f;
    }
    .search_input {
      padding: 0px 200px 0;
      height: 40px;
      overflow: hidden;
      transition: height 0.3s;
      /deep/.el-input__inner:focus {
        border-color: #c0c4cc;
      }
      .search_btn {
        width: 80px;
        height: 38px;
        text-align: center;
        line-height: 40px;
        background-color: #4b639f;
        border-radius: 2px;
        margin-left: 20px;
        color: #fff;
      }
    }
    .hide_search_input {
      height: 0;
    }
  }
  .tabs_box {
    display: flex;
    align-items: center;
    padding: 10px 0;
    position: sticky;
    top: 52px;
    z-index: 99;
    width: 100%;
    border-radius: 2px;
    background-color: #fff;
    border-bottom: 1px solid #c1c1c1;
    li {
      font-size: 18px;
      width: 110px;
      text-align: center;
      cursor: pointer;
      color: #4b639f;
      .iconfont {
        margin-right: 10px;
        font-size: 20px;
        font-weight: normal;
      }
    }
    .isTab {
      // color: #4b639f;
      font-weight: 700;
    }
    .line {
      position: absolute;
      left: 25px;
      bottom: 0px;
      width: 60px;
      height: 2px;
      border-radius: 2px;
      background-color: #4b639f;
      transform: translateX(0px);
      transition: all 0.3s;
    }
  }
}
</style>
