<template>
  <div class="playback_box">
    <ul class="video_list">
      <li @click="goVideoDetailsPage(playback)" v-for="playback in playbackList" :key="playback.id">
        <img class="playback_icon" src="@/assets/img/infoCollege/playback_icon.png" alt="icon">
        <div class="img_box">
          <img class="videoPic" :src="playback.meetingPic" alt="视频封面">
        </div>
        <p>{{playback.title}}</p>
        <i class="video_time">会议时间 {{$initTime(playback.meetingTime,'yyyy-MM-DD HH:mm')}}</i>
      </li>
    </ul>
    <div class="btn_box loading">
      <span v-if="isShow" @click="onGetMore">加载更多</span>
      <span v-if="!isOccupation && !isShow" style="color:#9195a3;">已经到底啦~</span>
    </div>
    <p v-if="isOccupation" class="occupation my_card">暂无数据</p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getPlaybackList, searchCollege } from '@/api/infoCollege.js'
export default {
  props: {
    isShowOptions: {
      type: Boolean,
      default: true
    },
    keyword: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      playbackList: [], // 回放列表
      pageObj: {
        pageNum: 1,
        pageSize: 12
      },
      isShow: true,
      isOccupation: false // 缺醒状态位
    }
  },
  methods: {
    // 跳转到视频详情页
    goVideoDetailsPage (playback) {
      if (!this.userId) {
        return this.$notify({
          title: '提示信息',
          message: '请您先登录再进行查看',
          offset: 100,
          type: 'warning'
        })
      }
      sessionStorage.setItem('videoObj', JSON.stringify({
        videoId: playback.id,
        videoType: '',
        optionId: '',
        modularType: 'playback'
      }))
      this.$router.push('/videoDetails')
    },
    onGetMore () {
      this.pageObj.pageNum++
      this.getPlaybackList()
    },
    // 获取列表
    getPlaybackList () {
      this.$nextTick(async () => {
        let res = {}
        if (this.isShowOptions) {
          // 普通
          res = await getPlaybackList(this.pageObj)
        } else {
          // 搜索
          res = await searchCollege({
            ...this.pageObj,
            keyword: this.keyword,
            category: 3
          })
        }

        this.isShow = res.data.length >= this.pageObj.pageSize
        this.playbackList = [...this.playbackList, ...res.data]
        if (this.playbackList.length === 0) {
          this.isOccupation = true
        } else {
          this.isOccupation = false
        }
      })
    }
  },
  computed: {
    ...mapGetters(['userId'])
  },
  watch: {
    isShowOptions () {
      this.pageObj.pageNum = 1
      this.playbackList = []
      this.getPlaybackList()
    }
  },
  created () {
    this.getPlaybackList()
  }
}
</script>
<style lang="less" scoped>
.playback_box {
  width: 100%;
  min-height: 500px;
  margin: 0 auto;
  padding: 20px 0;
  .video_list {
    display: flex;
    flex-wrap: wrap;
    li {
      position: relative;
      width: 18.63%;
      height: 240px;
      padding: 20px;
      background-color: #fff;
      margin: 0 20px 20px 0;
      border-radius: 5px;
      cursor: pointer;
      top: 0;
      transition: top 0.3s;
      &:nth-child(5n + 0) {
        margin-right: 0;
      }
      &:hover {
        top: -5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .videoPic {
          transform: scale(1.15);
        }
      }
      .playback_icon {
        position: absolute;
        left: 20px;
        bottom: 10px;
        z-index: 90;
        height: 14px;
        border-radius: 0 2px 0 0;
      }
      .videoPic {
        width: 100%;
        height: 110px;
        transform: scale(1.01);
        transition: transform 0.4s;
      }
      .img_box {
        position: relative;
        width: 100%;
        overflow: hidden;
      }
      p {
        margin-top: 10px;
        line-height: 20px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
      .video_time {
        position: absolute;
        bottom: 8px;
        right: 10px;
        color: #9195a3;
        font-size: 12px;
      }
    }
  }

  .btn_box {
    // height: 30px;
    font-size: 14px;
    text-align: center;
    span {
      display: inline-block;
      padding-bottom: 20px;
      line-height: 30px;
      cursor: pointer;
      color: #4b639f;
      &:hover {
        font-weight: 700;
      }
    }
  }
  .occupation {
    text-align: center;
    font-size: 16px;
    line-height: 100px;
  }
}
</style>
